export enum ETrasformType {
  replace = "replace",
  toString = "tostring"
}

export enum ERuleName {
  trimString = "trimString",
  replaceMultiSpaces = "replaceMultiSpaces",
  toString = "toString"
}

export interface ITransformRule {
  type: ETrasformType
  match?: RegExp | string
  replacer?: string
}

export type TRulesDictionary = {
  [key in ERuleName]: ITransformRule
}
