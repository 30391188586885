import {
  ERuleName,
  ETrasformType,
  ITransformRule,
  TRulesDictionary
} from "./transformers.model"

const rulesDictionary: TRulesDictionary = {
  trimString: {
    type: ETrasformType.replace,
    match: /^\s+|\s+$/gm,
    replacer: ""
  },
  replaceMultiSpaces: {
    type: ETrasformType.replace,
    match: /\s\s+/g,
    replacer: " "
  },
  toString: {
    type: ETrasformType.toString
  }
}

const getRule = (ruleName: ERuleName): ITransformRule =>
  rulesDictionary[ruleName]

const getTransformesRules = (rumeNames: ERuleName[]): ITransformRule[] =>
  rumeNames.map((ruleName) => getRule(ruleName))

const replaceStringTransformer = (
  value: any,
  match: RegExp | string = "",
  replacer: string = ""
): string =>
  typeof value === "string" ? value.replace(match, replacer) : value

export const transformers = {
  replaceStringTransformer,
  getTransformesRules
}
